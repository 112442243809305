
<template>
	<page>
		<module enable-flex>
			<m-search ref="search" @search="searchHandle">
				<m-search-item label="仓库名称">
					<c-input name="warehouse_name"></c-input>
				</m-search-item>
				<m-search-item label="类型">
					<c-select name="type" :options="warehouseType"></c-select>
				</m-search-item>
				<m-search-item label="状态">
					<c-checkbox-group name="state" :options="stateArr"></c-checkbox-group>
				</m-search-item>
			</m-search>

			<m-operate>
				<c-button v-if="auth('b_warehouse_edit')" color="sys" @click="createwarehouse">新建仓库</c-button>
			</m-operate>

			<c-table ref="table" height="grow">

				<c-table-column
					label="仓库名称"
					width="150"
					name="warehouse_name"
				></c-table-column>

				<c-table-column
					label="仓库类型"
					width="100"
					name="type"
				>
					<template #default="{data}">
						<span v-if="data.type == 1" style="color:blue">成品仓</span>
						<span v-else style="color:brown">物料仓</span>
					</template>
				</c-table-column>

			    <c-table-column
					  label="状态"
					  width="90"
					  name="state"
				 >
					  <template v-slot="{data}">
						  <span v-if="data.is_default == 1">
							   <span v-if="data.state == 1" style="color: green">启用</span>
							   <span v-else style="color: red">禁用</span>
						  </span>
						  <c-switch v-if="data.is_default == 2" :value="data.state" :state-value="[1,2]" :state-text="['启用','禁用']"
									@change="changeState"
									@beforeChange="beforeChangeState(arguments[0], arguments[1], data)"
						  ></c-switch>
					  </template>
				  </c-table-column>

				<c-table-column
					  label="是否默认"
					  width="90"
					  name="is_default"
				  >
					  <template v-slot="{data}">
						  <span v-if="data.state == 2">
							 <span v-if="data.is_default == 1" style="color: green">默认</span>
							 <span v-else style="color: red">非默认</span>
						  </span>
						  <c-switch v-else :value="data.is_default" :state-value="[1,2]" :state-text="['默认','非默认']"
									@change="changeDefault"
									@beforeChange="beforeChangeDefault(arguments[0], arguments[1], data)"
						  ></c-switch>
					  </template>
				  </c-table-column>

				<c-table-column
					label="备注"
					width="200"
					name="remark"
				>
					<template #default="{data}">
						<span v-if="data.remark">{{data.remark}}</span>
						<span v-else>--</span>
					</template>
				</c-table-column>

				<c-table-column
					label="创建时间"
					width="140"
					name="create_date"
				></c-table-column>

				<c-table-column
					type="button"
					label="操作"
					width="120"
				>
					<template v-slot="{data}">
						<c-table-button v-if="auth('b_warehouse_edit')" @click="editwarehouse(data)">编辑</c-table-button>
						<c-table-button v-if="auth('b_warehouse_edit') && data.is_default==2" @click="deletewarehouse(data)">删除</c-table-button>
					</template>
				</c-table-column>
			</c-table>
		</module>

		<c-dialog ref="warehouseEdit" :title="editState ? '编辑仓库' : '新建仓库'" width="600" @resolve="submit">
			<c-form ref="warehouseForm" unit-width="100%">
				<c-form-item label="仓库名称" required>
					<c-input name="warehouse_name"></c-input>
				</c-form-item>
				<c-form-item label="仓库类型" v-if="!editState" required>
					<c-select name="type" :options="warehouseType"></c-select>
				</c-form-item>
<!--				<c-form-item label="是否启用" required>
					<c-radio-group  name="state" :options="stateArr"></c-radio-group>
				</c-form-item>-->
				<c-form-item label="备注">
					<c-input type="textarea" name="remark"></c-input>
				</c-form-item>
				<c-input type="hidden" name="id"></c-input>
			</c-form>
		</c-dialog>

	</page>
</template>

<script>
export default {
	name: 'm_warehouse',
	components: {
	},
	data() {
		return {
			stateArr: [{
				name: '启用',
				value: 1
			}, {
				name: '禁用',
				value: 2
			}],
			warehouseType: [{
				name: '成品仓',
				value: "1"
			},{
				name: '物料仓',
				value: "2"
			}],
			editState: false
		}
	},
	computed: {
	},
	mounted() {
		this.$refs.search.search();
	},
	methods: {
		tabHandle(item) {
			this.$refs.search.search();
		},
		searchHandle(data) {
			this.$refs.table.load({
				url: '/warehouse/list',
				data
			});
		},
		//创建仓库
		createwarehouse() {
			this.editState = false;
			this.$refs.warehouseForm.clear();
			this.$refs.warehouseForm.set({});
			this.$refs.warehouseEdit.open();
		},
		//编辑仓库
		editwarehouse(val) {
			this.editState = true;
			this.$refs.warehouseForm.set(val);
			this.$refs.warehouseEdit.open();
		},
		//删除仓库
		deletewarehouse(val){
			this.$confirm({
				title: '确认',
				message: '确定要删除仓库【'+val.warehouse_name+'】吗？',  // 提示内容,
				buttonName: '确定',  // 确定按钮文字
				cancelButton: true,  // 是否显示取消按钮
				resolve: () => {
					this.request({
						url: '/warehouse/delete',
						data: {id:val.id},
						loading: true,
						success: data => {
							this.$message({
								message: '删除成功',
								type: 'success'
							});
							this.$refs.table.update();
						}
					});
				},  // 确定按钮回调
				cancel: () => {},  // 取消按钮回调
			});
		},
		//提交保存
		submit(stop) {
			stop();
			this.$refs.warehouseForm.submit({
				url: this.editState ? '/warehouse/update' : '/warehouse/add',
				rule: {
					warehouse_name: '请填写仓库名'
				},
				dataFormatter: data => {
					data.state = 2;//默认禁用
					return data;
				},
				success: (data, res) => {
					this.$message({
						message: '保存成功',
						type: 'success'
					});
					this.$refs.table.update();
					this.$refs.warehouseEdit.close();
				}
			});
		},
		/***
		 * 启用禁用前 回调函数
		 */
		beforeChangeState(state,next,data){
			// 确认弹窗
			this.$confirm({
				title: (state?'禁用':'启用')+'仓库',
				message: '是否确定【'+(state?'禁用':'启用')+'】仓库【'+data.warehouse_name+'】？',  // 提示内容,
				buttonName: '确定',  // 确定按钮文字
				cancelButton: true,  // 是否显示取消按钮
				resolve: () => {
					this.request({
						url: '/warehouse/state',
						data: {
							id:data.id,
							state:state?2:1
						},
						loading: true,
						success: data => {
							//远程接口调用完成，执行next方法，变更开关状态（执行失败则不变更状态）
							next();
						}
					});
				},  // 确定按钮回调
				cancel: () => {},  // 取消按钮回调
			});

		},
		/***
		 * 启用禁用 完成回调函数
		 */
		changeState(state){
			// 消息弹窗
			this.$message({
				message: (state?'启用':'禁用')+'成功',  // 提示内容,
				type: 'success'
			});
			this.$refs.table.update();
		},
		/***
		 * 默认/非默认前 回调函数
		 */
		beforeChangeDefault(state,next,data){
			// 确认弹窗
			this.$confirm({
				title: (state?'取消默认':'设置为默认')+'仓库',
				message: '是否确定将仓库【'+data.warehouse_name+'】【'+(state?'取消默认设置':'设置为默认')+'】？',  // 提示内容,
				buttonName: '确定',  // 确定按钮文字
				cancelButton: true,  // 是否显示取消按钮
				resolve: () => {
					this.request({
						url: '/warehouse/default',
						data: {
							id:data.id,
							is_default:state?2:1
						},
						loading: true,
						success: data => {
							//远程接口调用完成，执行next方法，变更开关状态（执行失败则不变更状态）
							next();
						}
					});
				},  // 确定按钮回调
				cancel: () => {},  // 取消按钮回调
			});

		},
		/***
		 * 默认/非默认 完成回调函数
		 */
		changeDefault(state){
			// 消息弹窗
			this.$message({
				message: (state?'设置为默认':'取消默认')+'成功',  // 提示内容,
				type: 'success'
			});
			this.$refs.table.update();
		},
	}
}
</script>